import React from 'react'
import * as S from '../../styles/components/gallery/galleryList.Style'

export default function Gallery(props) {
  const images = props.photos.map((photo, index) => {
    return (
      <S.GalleryItem
        src={photo.src}
        alt={photo.alt}
        key={index}
        onClick={() => props.onClick(index)}
      />
    )
  })

  return <S.GalleryContainer>{images}</S.GalleryContainer>
}
