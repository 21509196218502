import styled from 'styled-components'
import * as G from '../globalStyle'

export const MainHeader = styled(G.MainHeader)`
  width: 100%;
  text-align: center;
`

export const GallerySection = styled.section`
  position: relative;
  padding-top: 50px;
`

export const GalleryShape1 = styled(G.Shape)`
  top: 82%;
  right: 0;
  transform: rotate(101deg);

  svg {
    transform: scale(1.5);

    @media (min-width: ${props => props.theme.size.medium}) {
      transform: scale(2);
    }
  }
`
