import React from 'react'
import { graphql } from 'gatsby'
import { ImagesGallery } from '../components/gallery/imagesGallery'
import Layout from '../components/layout'
import * as G from '../styles/globalStyle'
import * as S from '../styles/pages/galleryStyle.js'
import * as Shape1 from '../assets/images/static/shape4.svg'

export default function Gallery({ data }) {
  const photos = []
  data.galleryImages.edges.forEach(elem => {
    photos.push({
      src: elem.node.fluid.src,
      alt:
        data.alts.edges[0].node.frontmatter[
          elem.node.fluid.originalName.slice(0, -4)
        ],
      height: 4,
      width: 3,
    })
  })

  return (
    <Layout>
      <S.GallerySection>
        <S.GalleryShape1>
          <Shape1 />
        </S.GalleryShape1>
        <G.Container>
          <S.MainHeader>Galeria</S.MainHeader>
          <ImagesGallery photos={photos} direction={'column'} />
        </G.Container>
      </S.GallerySection>
    </Layout>
  )
}

export const query = graphql`
  query GalleryQuery {
    alts: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "markdowns/gallery/alts.md/" } }
    ) {
      edges {
        node {
          frontmatter {
            salon_kosmetyki_profesjonalnej_evita_1
            salon_kosmetyki_profesjonalnej_evita_11
            salon_kosmetyki_profesjonalnej_evita_14
            salon_kosmetyki_profesjonalnej_evita_16
            salon_kosmetyki_profesjonalnej_evita_17
            salon_kosmetyki_profesjonalnej_evita_18
            salon_kosmetyki_profesjonalnej_evita_19
            salon_kosmetyki_profesjonalnej_evita_2
            salon_kosmetyki_profesjonalnej_evita_20
            salon_kosmetyki_profesjonalnej_evita_21
            salon_kosmetyki_profesjonalnej_evita_22
            salon_kosmetyki_profesjonalnej_evita_24
            salon_kosmetyki_profesjonalnej_evita_25
            salon_kosmetyki_profesjonalnej_evita_26
            salon_kosmetyki_profesjonalnej_evita_27
            salon_kosmetyki_profesjonalnej_evita_29
            salon_kosmetyki_profesjonalnej_evita_30
            salon_kosmetyki_profesjonalnej_evita_32
            salon_kosmetyki_profesjonalnej_evita_33
            salon_kosmetyki_profesjonalnej_evita_34
            salon_kosmetyki_profesjonalnej_evita_35
            salon_kosmetyki_profesjonalnej_evita_36
            salon_kosmetyki_profesjonalnej_evita_38
            salon_kosmetyki_profesjonalnej_evita_37
            salon_kosmetyki_profesjonalnej_evita_4
            salon_kosmetyki_profesjonalnej_evita_41
            salon_kosmetyki_profesjonalnej_evita_42
            salon_kosmetyki_profesjonalnej_evita_46
            salon_kosmetyki_profesjonalnej_evita_47
            salon_kosmetyki_profesjonalnej_evita_48
            salon_kosmetyki_profesjonalnej_evita_5
            salon_kosmetyki_profesjonalnej_evita_50
            salon_kosmetyki_profesjonalnej_evita_51
            salon_kosmetyki_profesjonalnej_evita_53
            salon_kosmetyki_profesjonalnej_evita_54
            salon_kosmetyki_profesjonalnej_evita_55
            salon_kosmetyki_profesjonalnej_evita_56
            salon_kosmetyki_profesjonalnej_evita_57
            salon_kosmetyki_profesjonalnej_evita_6
            salon_kosmetyki_profesjonalnej_evita_8
            salon_kosmetyki_profesjonalnej_evita_9
          }
        }
      }
    }
    galleryThumbnails: allImageSharp(
      filter: {
        fluid: {
          originalName: {
            regex: "/salon_kosmetyki_profesjonalnej_evita_.*.jpg/"
          }
        }
      }
    ) {
      edges {
        node {
          fluid(maxWidth: 560) {
            src
            originalName
          }
        }
      }
    }
    galleryImages: allImageSharp(
      filter: {
        fluid: {
          originalName: {
            regex: "/salon_kosmetyki_profesjonalnej_evita_.*.jpg/"
          }
        }
      }
    ) {
      edges {
        node {
          fluid(maxWidth: 2000) {
            src
            originalName
          }
        }
      }
    }
  }
`
